import Cookies from 'js-cookie'
import axios from 'axios'

export const xsrfTokenInterceptor = (config) => {
  const token = Cookies.get('XSRF-TOKEN')

  if (token && config.withCredentials) config.headers['X-Xsrf-Token'] = token

  return config
}

const request = axios.create({
  baseURL: process.env.VUE_APP_LOGIN_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

request.interceptors.request.use(xsrfTokenInterceptor)

export default request
