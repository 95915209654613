import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings'
import base from './modules/base'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base,
    settings
  }
})
