export default {
  footer: {
    links: [
      {
        tag: 'Home',
        route: process.env.VUE_APP_HOME
      },
      {
        tag: 'Suporte',
        route: 'https://ajuda.convenia.com.br/knowledge'
      },
      {
        tag: 'Privacidade',
        route: process.env.VUE_APP_HOME + '/politica-de-privacidade'
      },
      {
        tag: 'Termos',
        route: process.env.VUE_APP_HOME + '/termos-de-uso'
      }
    ],
    text: `© ${new Date().getFullYear()} Convenia - CONVENIA ATIVIDADES DE INTERNET E CORRETORA DE SEGUROS LTDA.`
  },

  signin: {
    title: 'Entrar',
    text: 'Informe seu e-mail e senha para entrar na plataforma.',
    info: {
      title: 'Olá, amigo(a)',
      text: 'Informe seu e-mail e senha para entrar na plataforma.'
    },
    actions: {
      p: '',
      a: 'Esqueceu sua senha?',
      button: 'Entrar'
    }
  },

  'login-social': {
    title: 'Entrar',
    text: 'Informe seu e-mail e senha para entrar na plataforma.',
    info: {
      title: 'Olá, amigo(a)',
      text: 'Informe seu e-mail e senha para entrar na plataforma.'
    },
    actions: {
      p: '',
      a: 'Esqueceu sua senha?',
      button: 'Entrar'
    }
  },

  twoFactor: {
    title: 'Código de confirmação',
    text: 'Digite o código de confirmação informado no seu e-mail',
    info: {
      title: '',
      text: ''
    },
    actions: {
      a: 'Voltar ao login',
      button: 'Confirmar'
    }
  },

  passwordRecovery: {
    title: 'Esqueci a senha',
    text: 'Digite seu e-mail e enviaremos um e-mail para você informando como recuperá-la.',
    info: {
      title: 'Tranquilo,',
      text: 'Vamos te ajudar. Informe os dados necessários para recuperar a sua senha.'
    },
    actions: {
      p: 'Lembrou a senha? ',
      a: 'Entrar',
      button: 'Recuperar senha'
    }
  },

  passwordReset: {
    title: 'Redefinir a senha',
    text: 'Defina uma senha segura para ter acesso à plataforma convenia.',
    info: {
      title: 'Olá, amigo(a)',
      text: 'Vamos te ajudar. Informe os dados necessários para redefinir a sua senha.'
    },
    actions: {
      p: '',
      a: '',
      button: 'Redefinir a senha'
    }
  },

  error404: {
    title: 'Eita, algo deu errado...',
    text: 'A página solicitada não pôde ser encontrada.',
    info: { title: '', text: '' },
    icon: 'Error404',
    button: 'Voltar'
  }
}
