export default {
  data () {
    return {
      xsMobileMedia: window.matchMedia('(max-width: 576px)'),
      mobileMedia: window.matchMedia('(max-width: 1023px)'),
      isXsMobile: false,
      isMobile: false
    }
  },

  methods: {
    setBreakpoint () {
      this.isMobile = this.mobileMedia && this.mobileMedia.matches
      this.isXsMobile = this.xsMobileMedia && this.xsMobileMedia.matches
    },

    getIsXsMobile () {
      return this.isXsMobile
    },

    getIsMobile () {
      return this.isMobile
    }
  },

  beforeDestroy () {
    this.mobileMedia.removeListener(this.setBreakpoint)
    this.xsMobileMedia.removeListener(this.setBreakpoint)
  },

  mounted () {
    this.mobileMedia.addListener(this.setBreakpoint)
    this.xsMobileMedia.addListener(this.setBreakpoint)
    this.setBreakpoint()
  },

  provide () {
    return {
      getIsXsMobile: this.getIsXsMobile,
      getIsMobile: this.getIsMobile
    }
  }
}
