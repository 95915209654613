<template>
  <div :class="classes">
    <label v-if="label || labelIcon" class="label">
      <span v-if="required" class="required">*</span>

      <c-icon
        v-if="labelIcon"
        :size="labelIconSize"
        :icon="labelIcon"
        class="icon"
      />

      <span v-else class="text">{{ label }}</span>
    </label>

    <!-- @slot The default slot for the form field. -->
    <slot />

    <transition name="fade">
      <div v-if="!!validation" class="validation">
        <p class="message">
          <c-flag error class="flag" icon="exclamation" size="9" />
          <span class="text" v-html="validation" />
        </p>
      </div>
    </transition>

    <transition name="fade">
      <div
        class="jumbo-validation"
        ref="jumbo-validation"
        v-show="!!validation"
        @mouseenter="toggleHover"
        @mouseleave="toggleHover"
      >
        <c-tooltip
          :show="showTooltip"
          :font-size="14"
          :element-spacing="5"
          :target="$refs['jumbo-validation']"
          :max-width="205"
          align="left"
          position="top"
          dark
        > {{ validation }} </c-tooltip>

        <c-icon
          icon="warning-circle"
          class="icon"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CFlag from '../CFlag'
import CIcon from '../CIcon'

/**
 * The container component for form fields, anything from select fields, input
 * or checkboxes. Adds standard styles for label, validations and etc.
 */
export default {
  name: 'CInputContainer',
  components: { CFlag, CIcon },

  props: {
    /**
     * The label of the field.
     */
    label: {
      type: String,
      default: ''
    },

    /**
     * Uses a icon a for the field label.
     */
    labelIcon: String,

    /**
     *
     */
    labelIconSize: {
      type: [String, Number],
      default: 24
    },

    /**
     * Displays the label on the left-side instead of in the top.
     */
    labelLeft: Boolean,

    /**
     * The field validation message.
     */
    validation: String,

    /**
     * Adds a visual markes to mark the field as required.
     */
    required: Boolean,

    /**
     * Force tooltip to display.
     */
    forceTooltip: Boolean,

    /**
     * Clears forced tooltip.
     */
    clearTooltip: Boolean
  },

  data: () => ({
    hover: false,
    forceTooltipBuffer: false
  }),

  computed: {
    classes () {
      return [ 'c-input-container', {
        '-label': this.label,
        '-label-left': this.labelLeft,
        '-validation': this.validation
      }]
    },

    showTooltip () {
      return this.hover || this.forceTooltipBuffer
    }
  },

  methods: {
    toggleHover () {
      if (this.forceTooltip) this.forceTooltipBuffer = false
      this.hover = !this.hover
    }
  },

  watch: {
    forceTooltip (val) {
      this.forceTooltipBuffer = val
    },
    clearTooltip (val) {
      if (val) this.forceTooltipBuffer = !val
    }
  },

  mounted () {
    this.forceTooltipBuffer = this.forceTooltip
  }
}
</script>

<style lang="scss">
.c-input-container {
  position: relative;

  & > .jumbo-validation { display: none; }
  &.-jumbo > .jumbo-validation { display: block; }

  &.-label:not(.-label-left) {
    margin-top: 22px;
    &.multi-check, &.radio { margin-top: 30px; }
  }
  &.-validation:not(:last-child) { margin-bottom: 53px; }
  &.-validation.-label-left:not(:last-child) { margin-bottom: 35px; }

  &.-label-left > .label {
    display: flex;
    align-items: center;

    height: 40px;

    top: 0px;
    right: 100%;
    white-space: nowrap;
  }

  & > .label {
    position: absolute;
    top: -20px;
    font-size: 11px;
    margin-bottom: 5px;
    padding-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: $title-font-family;
    color: map-get($text-color, base-50);

    & > .required { color: $negative-color; margin-top: 5px; }

    & > .icon { fill: map-get($text-color, base-30); }
  }

  & > .validation {
    overflow: hidden;
    height: 17px;
    width: calc(100%);
    position: absolute;
    bottom: -23px;

    & > .message {
      display: flex;
      align-items: center;
      color: $negative-color;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;

      & > .flag {
        padding: 2px;
        margin-right: 5px;
        height: 13px;
        width: 13px;
      }
      & > .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        & > b { -webkit-font-smoothing: antialiased; }
      }
    }
  }

  & > .jumbo-validation {
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    width: 20px;
    height: 20px;

    & > .icon { fill: map-get($negative-color-map, light); }
  }

  & > .fade-enter-active,
  & > .fade-leave-active {
     transition: opacity .3s !important;
   }

  & > .fade-enter,
  & > .fade-leave-to {
    opacity: 0 !important;
  }
}
</style>
