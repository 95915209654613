<template>
  <transition name="c-tooltip-show">
    <c-popover
      v-if="show"
      ref="popover"
      v-bind="$attrs"
      :class="['c-tooltip', { '-dark': dark }]"
      @mouseover.stop
    >
      <div class="card" :style="cardSytles">
        <slot  />
      </div>
    </c-popover>
  </transition>
</template>

<script>
import CPopover from '../CPopover'

/**
 * Tooltip component used by the v-tooltip directive.
 */
export default {
  name: 'CTooltip',
  components: { CPopover },

  props: {
    /**
     * Whether to show or hide the toolip component.
     */
    show: Boolean,

    /**
     * Makes the toolip bg-color dark.
     */
    dark: Boolean,

    /**
     * The tooltip's font-size
     */
    fontSize: {
      type: Number,
      default: 11
    },

    /**
     * The tooltip's max-width
     */
    maxWidth: {
      type: Number,
      default: 200
    }
  },

  computed: {
    cardSytles () {
      return {
        '--tooltip-font-size': `${this.fontSize}px`,
        '--tooltip-max-width': `${this.maxWidth}px`
      }
    }
  },

  methods: {
    setPosition () {
      this.$refs.popover && this.$refs.popover.setPosition()
    }
  }
}
</script>

<style lang="scss">
.c-tooltip {
  pointer-events: none;

  &.c-tooltip::before { z-index: inherit; }

  & > .card {
    padding: 10px;
    color: map-get($text-color, base-80);
    font-family: $base-font-family;
    -webkit-font-smoothing: antialiased;
    line-height: 15px;
    font-size: var(--tooltip-font-size);
    max-width: var(--tooltip-max-width);
    box-shadow: $box-shadow;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-color: #FFF;
  }

  &.-dark {
    &::before { background-color: map-get($text-color, base); }
    & > .card {
      color: rgba(#FFF, 0.8);
      background-color: #121E48;
    }
  }
}

.c-tooltip-show {
  &-enter-active,
  &-leave-active { transition: opacity .3s; }

  &-enter,
  &-leave-to { opacity: 0; }
}
</style>
