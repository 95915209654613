import * as types from '@store/types'
import request from '@modules/request'
import Cookie from 'js-cookie'

const state = {
  captchaToken: ''
}

const mutations = {
  [types.LOGIN_UPDATE_CAPTCHA_TOKEN]: (state, captchaToken) => {
    state.captchaToken = captchaToken
  },

  [types.LOGIN_SIGN_IN_REDIR]: (_, data, keepConnected) => {
    const redir = process.env.VUE_APP_REDIRECT
    const domain = process.env.VUE_APP_COOKIE_DOMAIN

    Object.entries(data || {})
      .forEach(([ cookieName, cookieVal ]) =>
        Cookie.set(cookieName, cookieVal, { domain }))

    if (keepConnected) {
      // 86400 = seconds in a day
      const expires = Math.round(data.expires_in / 86400)
      Cookie.set('refresh_token', data.refresh_token,
        { domain, expires })
    }

    window.location = redir
  },
}

const actions = {
  [types.LOGIN_SIGN_IN_ERR]: ({ getters, commit, dispatch }, err) => {
    const response = (err|| {}).response
    const status = (response || {}).status
    const errorType = ((response || {}).data || {}).error

    if (!!response && errorType === 'invalid_credentials')
      commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, 'E-mail ou senha inválidos')
    else if (!!response && status === 429)
      commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, 'Usuário bloqueado! Muitas tentativas de senha incorreta. Tente novamente mais tarde.')
    else
      commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, 'Não foi possível autenticar o usuário')

    if (getters[types.LOGIN_BUSY])
      commit(types.LOGIN_UPDATE_BUSY)

    dispatch(types.LOGIN_GET_CAPTCHA_TOKEN)
  },
  [types.LOGIN_SIGN_IN]: async ({ state, commit, dispatch }, actionData) => {
    commit(types.LOGIN_UPDATE_BUSY)
    commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, '')

    const payload = {
      ci_login: true,
      email: actionData.email,
      password: actionData.password,
      captcha: state.captchaToken
    }

    return request
      .post('/api/v1/login', payload, { withCredentials: true })
      .then(({ data }) => {
        if ((data || {}).two_factor) {
          commit(types.LOGIN_UPDATE_BUSY)
          return { redirect: true }
        }

        commit(types.LOGIN_SIGN_IN_REDIR, data, actionData.keep_connected)
        return {}
       })
      .catch(err => dispatch(types.LOGIN_SIGN_IN_ERR, err))
  },

  [types.LOGIN_SOCIALITE]: async ({ commit, dispatch }) => {
    const success = ({ data }) => {
      if (!!(data || {}).link)
        window.location = (data || {}).link
      else
        dispatch(
          types.LOGIN_SIGN_IN_ERR,
          new Error('Não foi possível realizer login.')
        )

      commit(types.LOGIN_UPDATE_BUSY)
    }

    const failure = (err) => {
      dispatch(types.LOGIN_SIGN_IN_ERR, err)
      commit(types.LOGIN_UPDATE_BUSY)
    }

    return request
      .get('/api/v1/auth/social/google', { withCredentials: false })
      .then(success)
      .catch(failure)
  },

  [types.LOGIN_SOCIALITE_CB]: async ({ commit, dispatch }, params) => {
    const success = ({ data }) => {
      commit(types.LOGIN_SIGN_IN_REDIR, data)
      commit(types.LOGIN_UPDATE_BUSY)
    }

    const failure = (err) => {
      dispatch(types.LOGIN_SIGN_IN_ERR, err)
      commit(types.LOGIN_UPDATE_BUSY)
    }

    return request
      .get('/api/v1/auth/social/google/core-callback', { params, withCredentials: true })
      .then(success)
      .catch(failure)
  },

  [types.LOGIN_RESEND_CODE]: async ({ commit, dispatch }) => {
    return request
      .post('/api/v1/resend-two-factor-secret-key/send', {}, { withCredentials: true })
      .then(() => {})
      .catch(err => dispatch(types.LOGIN_SIGN_IN_ERR, err))
  },

  [types.LOGIN_2FA]: async ({ commit, dispatch }, code) => {
    return request
      .post('/api/v1/two-factor-challenge', { code }, { withCredentials: true })
      .then(({ data }) => commit(types.LOGIN_SIGN_IN_REDIR, data))
      .catch(err => dispatch(types.LOGIN_SIGN_IN_ERR, err))
  },

  [types.LOGIN_SEND_EMAIL]: async ({ commit }, email) => {
    await request
      .post('/api/v1/forgot-password', { email }, { withCredentials: true })
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  },

  [types.LOGIN_VALIDATE_RESET_TOKEN]: async ({ commit, getters }, data) => {
    const { token, email } = data

    await request
      .post('/api/v1/user/password/token-verify', { email, token }, { withCredentials: true })
      .then(({ data }) => {
        if (!data.valid) return commit(types.LOGIN_UPDATE_FEEDBACK, 'expired')

        commit(types.LOGIN_UPDATE_RESET_TOKEN, token)
        commit(types.LOGIN_UPDATE_RESET_EMAIL, email)
      })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'expired') })
      .then(() => { commit(types.LOGIN_UPDATE_CONTENT, 'passwordReset') })
  },

  [types.LOGIN_RESET_PASSWORD]: async ({ commit, getters }, data) => {
    const payload = {
      token: getters[types.LOGIN_RESET_TOKEN],
      email: getters[types.LOGIN_RESET_EMAIL],
      password: data.password,
      password_confirmation: data.password_confirmation
    }

    await request
      .post('/api/v1/reset-password', payload, { withCredentials: true })
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  },
  [types.LOGIN_GET_CAPTCHA_TOKEN]: ({ commit }) => {
    /* eslint-disable no-undef */
    const getCaptcha = () => grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.VUE_APP_CAPTCHA_TOKEN, { action: 'login' })
        .then(async token => { commit(types.LOGIN_UPDATE_CAPTCHA_TOKEN, token) })
    })

    if (window.hasOwnProperty('grecaptcha'))
      getCaptcha()
    else
      setTimeout(getCaptcha, 2000)
  }
}

export default { state, actions, mutations }
