<template lang="html">
  <div
    :class="classes"
    id="login-container"
    style="overflow-x: hidden;"
  >
    <login-warning
      :message="errorFeedback"
      :target="warningTarget"
    />

    <login-modal
      v-if="isMobile"
      no-back
      :key="content+feedback"
      :app-type="appType"
      :loading-button="loadingButton"
      @submit="submit"
      @toggle="toggle"
      @back="toggle"
      @social-login="socialLogin"
      ref="login-mobile"
    />

    <div v-else class="overlay">
      <div class="logo-wrapper">
        <img v-if="logo" :src="logo" class="logo" />

        <template v-else>
          <img
            v-if="appType === 'bpo'"
            width="130"
            :src="bpoLogo()"
          />

          <c-logo v-else width="130" arc-color="purple" />
        </template>
      </div>

      <desktop-login
        :content="content"
        :app-type="appType"
        :login-type="loginType"
        :loading-button="loadingButton"
        @toggle="toggle"
        @submit="submit"
        @social-login="socialLogin"
        @resend-mfa-code="resendMfa"
        ref="login-desktop"
      />

      <div class="footer">
        <ul class="links">
          <li
            v-for="link in footerLinks"
            class="link"
            :key="link.tag"
          >
            <a :href="link.route">{{ link.tag }}</a>
          </li>
        </ul>

        <p class="text">
          {{ footerText }}
        </p>
      </div>

      <div class="background">
        <shape-left class="shape-left" />
        <shape-right class="shape-right" />
        <div class="first-overlay" />
        <div class="second-overlay" />
      </div>
    </div>
  </div>
</template>

<script>
import MediaQuery from '@mixins/MediaQuery'

import { mapActions, mapGetters } from 'vuex'
import * as types from '@store/types'

const domainsWithSSOOnly = [
  'omie.com.br',
  'linker.com.br',
  'oneflow.com.br',
  'gclick.com.br',
  'jeitto.com.br',
  'lumis.com.br'
]

export default {
  mixins: [ MediaQuery ],

  data: () => ({
    loadingButton: false,
    warningTarget: null,
    warningTgtAttempts: 0
  }),

  components: {
    LoginModal: () => import('@components/login/Modal'),
    DesktopLogin: () => import('@components/login/Desktop'),
    ShapeLeft: () => import('@components/login/ShapeLeft'),
    ShapeRight: () => import('@components/login/ShapeRight'),
    LoginWarning: () => import('@components/login/Warning')
  },

  computed: {
    ...mapGetters({
      content: types.LOGIN_CONTENT,
      feedback: types.LOGIN_FEEDBACK,
      errorFeedback: types.LOGIN_ERROR_FEEDBACK,
      logo: types.SETTINGS_LOGO,
      LoginTexts: types.SETTINGS_TEXTS,
      appType: types.SETTINGS_APP_TYPE,
      loginType: types.SETTINGS_LOGIN_TYPE
    }),

    footerText () {
      return this.LoginTexts.footer.text
    },
    footerLinks () {
      return this.LoginTexts.footer.links
    },
    classes () {
      return ['convenia-login', {
        '-bpo': this.appType === 'bpo'
      }]
    }
  },

  methods: {
    ...mapActions({
      updateFeedback: types.LOGIN_UPDATE_FEEDBACK,
      updateErrorFeedback: types.LOGIN_UPDATE_ERROR_FEEDBACK,
      goTo: types.LOGIN_GO_TO,
      signIn: types.LOGIN_SIGN_IN,
      resetPassword: types.LOGIN_RESET_PASSWORD,
      sendEmail: types.LOGIN_SEND_EMAIL,
      confirmTwoFactor: types.LOGIN_2FA,
      socialLogin: types.LOGIN_SOCIALITE,
      resendMfa: types.LOGIN_RESEND_CODE
    }),

    async submit (data) {
      this.loadingButton = true

      if (this.content === 'signin' && !this.feedback) {
        // Enforces SSO login for some companies
        if (domainsWithSSOOnly.includes(data.email.split('@').pop())) {
          this.loadingButton = false
          this.socialLogin()
          return
        }

        const { redirect } = await this.signIn({
          email: data.email,
          password: data.password,
          ci_login: true,
          keep_connected: data.keep_connected
        }) || {}

        if (redirect) {
          this.goTo('twoFactor')
          this.$router.push({ name: 'two-factor' })
          this.loadingButton = false
          return
        }
      }

      else if (this.content === 'twoFactor' && !this.feedback)
        await this.confirmTwoFactor(data.code)

      else if (this.content === 'passwordRecovery' && !this.feedback)
        await this.sendEmail(data.email)

      else if (this.content === 'passwordRecovery' && this.feedback === 'err')
        this.goTo('passwordRecovery')

      else if (this.content === 'passwordReset' && !this.feedback) {
        await this.resetPassword({
          password: data.password,
          password_confirmation: data.repeatedPassword
        })
      }

      else if (this.content === 'passwordReset' && this.feedback === 'err')
        this.updateFeedback('')

      else if (this.content === 'passwordReset' && this.feedback === 'expired') {
        this.goTo('passwordRecovery')
        this.$router.push({ name: 'signin' })
      }

      else this.goTo('signin')

      this.loadingButton = false
    },

    toggle (val) {
      if (this.content === 'signin') {
        this.goTo('passwordRecovery')
        this.$router.push({ name: 'forgot-password' })
      } else if (this.content === 'passwordRecovery') {
        this.goTo('signin')
        this.$router.push({ name: 'signin' })
      } else if (this.content === 'twoFactor') {
        this.goTo('signin')
        this.$router.push({ name: 'signin' })
      }
    },

    bpoLogo () {
      return require('@assets/logo_bpo.svg')
    },

    updateWarningTarget () {
      const elRef = this.isMobile
        ? 'login-mobile' : 'login-desktop'
      const element = (this.$refs[elRef] || {}).$el

      if (!element && this.warningTgtAttempts < 10) {
        this.warningTgtAttempts += 1
        return setTimeout(this.updateWarningTarget, 300)
      }

      this.warningTarget = element
    }
  },

  watch: {
    errorFeedback: {
      immediate: true,
      handler (val) {
        this.updateWarningTarget()

        if (val) {
          setTimeout(() => {
            this.updateErrorFeedback('')
          }, 10000)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.convenia-login {
  & > .overlay {
    @include hide;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    overflow-y: auto;
    z-index: -1;

    & > .logo-wrapper {
      width: fit-content;
      padding: 40px 40px 30px;

      & > .logo { height: 40px; }
    }

    & > .footer { margin: 32px 40px 40px; }

    & > .footer > .links { display: flex; }

    & > .footer > .links > .link {
      margin-right: 10px;
      font-size: 12px;
      letter-spacing: $shorter-letter-spacing;
      line-height: 22px;
      text-transform: uppercase;
      font-weight: 700;
      color: map-get($text-color, base-50);

      & > a:link, a:visited { color: inherit }
    }

    & > .footer > .text {
      font-size: 12px;
      letter-spacing: $shorter-letter-spacing;
      line-height: 22px;
    }

    & > .background {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      & > .shape-left {
        position: absolute;
        left: 0;
        bottom: -120px;
        height: 525px;
      }

      & > .shape-right {
        position: absolute;
        top: -100px;
        right: calc(16vw - 204px);
        height: 400px;
      }

      & > .first-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.05;
        background-image: linear-gradient(-135deg, rgba(18,30,72,0.90) 0%, #121E48 100%);
      }

      & > .second-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.05;
        background-image: linear-gradient(-134deg, rgba(0,0,0,0.00) 0%, #000000 100%);
      }
    }
  }

  &.-bpo > .overlay > .background {
    & > .shape-left > #left-shape-Page-1 {
      opacity: 1;

      #left-shape-Mask { opacity: 0.05; }
      #left-shape-Banner { opacity: 0.03; }
    }
    & > .shape-right > #right-shape-Page-1 {
      opacity: 1;

      #right-shape-Oval { opacity: 0.05; }
      #right-shape-Shape { opacity: 0.03; }
    }
  }

  @include tablet (true) {
    & > .login-modal { @include hide; }
    & > .overlay {
      @include show;

      & > .background {
        & > .shape-left {
          height: 35vw;
        }

        & > .shape-right {
          right: 0;
          height: 30vw;
        }
      }
    }
  }
}
</style>
