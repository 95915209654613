import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import * as components from './components/global/index'

Vue.config.productionTip = false

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
