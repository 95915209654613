<template>
  <component
    :is="`h${size}`"
    class="c-title"
    :class="titleClasses"
  >
    <c-icon
      v-if="icon"
      :icon="icon"
      :size="iconSize"
      class="icon"
    />

    <span class="text">
      <!-- @slot The content slot. -->
      <slot />
    </span>

    <span
      v-if="!noBorder"
      :class="borderClasses"
      :style="borderStyles"
    />
  </component>
</template>

<script>
import CIcon from '../CIcon'

/**
 * A title component.
 */
export default {
  name: 'CTitle',
  components: { CIcon },

  props: {
    /**
     * Size of the title from 1 to 6, corresponds to: h1, h2, h3, h4, h5, h6.
     */
    size: {
      type: [String, Number],
      default: 3
    },

    /**
     * The icon the show alongside the title.
     */
    icon: {
      type: String,
      default: ''
    },

    /**
     * The size of the icon.
     */
    iconSize: {
      type: [String, Number],
      default: '25px'
    },

    /**
     * The color of the bottom border of the title. Overrides
     * the `red`, `green` and `yellow` props.
     */
    borderColor: {
      type: [String, Array],
      validator: (color) => typeof color === 'string' || color.length
    },

    /**
     * Paints the border with the default red gradient.
     */
    red: Boolean,

    /**
     * Paints the border with the default green gradient.
     */
    green: Boolean,

    /**
     * Centers the border relative to the text.
     */
    center: Boolean,

    /**
     * Paints the border with the default yellow gradient.
     */
    yellow: Boolean,

    /**
     * Paints the border with a grayish color map-get($text-color, base-10)
     */
    grey: Boolean,

    /*
     * Paints the border with solid white.
     */
    white: Boolean,

    /**
     * Removes the title border.
     */
    noBorder: Boolean,

    /**
     * Makes the text opacity darker (map-get($text-color, base-50))
     */
    darkText: Boolean
  },

  computed: {
    borderClasses () {
      return [ 'border',
        {
          '-yellow': !this.noBorder && this.yellow,
          '-green': !this.noBorder && this.green,
          '-grey': !this.noBorder && this.grey,
          '-red': !this.noBorder && this.red,
          '-white': !this.noBorder && this.white
        }
      ]
    },

    titleClasses () {
      return {
        '-border': !this.noBorder,
        '-center': this.center,
        '-dark-text': this.darkText
      }
    },

    borderStyles () {
      const background =
        Array.isArray(this.borderColor) && !!this.borderColor.length
          ? `linear-gradient(90deg, ${this.borderColor.join(', ')})`
          : this.borderColor

      return { background }
    }
  }
}
</script>

<style lang="scss">
$c-title-bar-size: 90px 3px;

.c-title {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;

  & > .icon {
    margin-right: 10px;
    vertical-align: bottom;
    fill: map-get($text-color, base-50);
  }

  & > .text {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: map-get($text-color, base-50);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & > .border {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 90px;
    height: 4px;
    border-radius: 4px;

    background: set-linear-gradient();
    transition: background .3s;

    &.-green {
      background: set-linear-gradient(horizontal, $positive-color-map);
    }

    &.-yellow {
      background: set-linear-gradient(horizontal, $alert-color-map);
    }

    &.-red {
      background: set-linear-gradient(horizontal, $negative-color-map);
    }

    &.-grey {
      background: map-get($text-color, base-10);
    }

    &.-white {
      background: #fff;
    }
  }

  &.-border { padding-bottom: 12px; }

  &.-center > .border { left: 50%; transform: translateX(-50%); }

  &.-dark-text > .text { color: map-get($text-color, base-80); }
}
</style>
