<template>
  <svg
    :class="['c-selectable-check', { '-selected': selected }]"
    :style="styles"
    width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
    <defs>
      <linearGradient id="c-selectable-linearGradient-3" x1="0%" y1="50%" x2="97.6368651%" y2="50%">
        <stop stop-color="#3CE798" offset="0%" />
        <stop stop-color="#37CFCC" offset="100%" />
      </linearGradient>
      <filter id="c-selectable-filter-4" x="-23.2%" y="-30.8%" width="146.3%" height="161.7%" filterUnits="objectBoundingBox" >
        <feGaussianBlur stdDeviation="1" in="SourceGraphic" />
      </filter>
      <filter id="c-selectable-filter-5" x="-23.2%" y="-30.8%" width="146.3%" height="161.7%" filterUnits="objectBoundingBox" >
        <feDropShadow dx="0" dy="2" stdDeviation="1" flood-color="#000" flood-opacity="0.3"/>
      </filter>
    </defs>
    <g id="c-selectable-Symbols" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g id="c-selectable-card-/-colab-hover" transform="translate(-211.000000, -20.000000)">
        <g id="c-selectable-Group-2" transform="translate(213.000000, 22.000000)">
          <path d="M18.2113333,7.32383333 L9.79166667,15.741875 L6.78866667,12.7405 L6.02329167,13.505875 L9.40870833,16.8912917 C9.51379167,16.996375 9.65245833,17.0505417 9.791125,17.0505417 C9.92979167,17.0505417 10.069,16.998 10.1735417,16.8912917 L18.975625,8.08920833 L18.21025,7.32383333 L18.2113333,7.32383333 Z" fill-rule="nonzero" opacity="0.9" filter="url(#c-selectable-filter-4)" />
          <g transform="translate(6.000000, 6.000000)"  >
            <g transform="translate(0.000000, 1.300000)">
              <path
                :filter="checkFilter"
                d="M12.2113333,0.0238333333 L3.79166667,8.441875 L0.788666667,5.4405 L0.0232916667,6.205875 L3.40870833,9.59129167 C3.51379167,9.696375 3.65245833,9.75054167 3.791125,9.75054167 C3.92979167,9.75054167 4.069,9.698 4.17354167,9.59129167 L12.975625,0.789208333 L12.21025,0.0238333333 L12.2113333,0.0238333333 Z"
                fill-rule="nonzero" />
            </g>
          </g>
          <g transform="translate(6.000000, 7.300000)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: Boolean,
    activeIconColor: String,
    hoverIconColor: {
      type: String,
      default: 'url(#c-selectable-linearGradient-3)'
    }
  },

  computed: {
    styles () {
      return {
        '--active-icon-color': this.activeIconColor,
        '--hover-icon-color': this.hoverIconColor
      }
    },
    checkFilter () {
      return this.selected ? 'url(#c-selectable-filter-5)' : ''
    }
  }
}
</script>

<style lang="scss">
.c-selectable-check,
.c-selectable-check-active {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.20);
}

.c-selectable-check {
  // fill: var(--hover-icon-color);
  fill: var(--hover-icon-color);
}

.c-selectable-check-active {
  fill: var(--active-icon-color);
}

</style>
