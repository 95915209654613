<template>
  <component
    v-mask="mask"
    :is="componentType"
    v-bind="inputAttrs"
    :value="value"
    v-html="textArea && value"
    class="c-input-abstract"
    v-on="inputListeners"
    @keydown.native="checkKey"
  />
</template>

<script>
import { mask } from 'vue-the-mask'
import { Money } from 'v-money'

/**
 * A reusable component that contains and abstracts all of the
 * essential input logic.
 */
export default {
  name: 'CInputAbstract',
  components: { Money },

  directives: {
    mask (el, binding) {
      if (!(binding || {}).value || !(binding || {}).value.length) return

      return mask(el, binding)
    }
  },

  props: {
    /**
     * The value of the input, durr.
     * @model
     */
    value: [String, Number],

    /**
     * Whether it is a textarea or not.
     */
    textArea: Boolean,

    /**
     * Whether to apply the money mask.
     */
    isMoney: Boolean,

    /**
     * Money mask options.
     */
    moneyMask: {
      type: Object,
      default: () => ({
        prefix: 'R$ ',
        decimal: ',',
        thousands: '.'
      })
    },

    /**
     * Value mask (not to be confunded with moneyMask)
     */
    mask: {
      type: [String, Array],
      default: ''
    },

    /**
     * Allow only positive numbers to be inserted on the input.
     */
    positiveOnly: Boolean
  },

  computed: {
    componentType () {
      return this.isMoney ? 'money'
        : this.textArea ? 'textarea' : 'input'
    },
    inputListeners () {
      const emitInput = (ev) => {
        const value = typeof (ev.target || {}).value === 'string'
          ? (ev.target || {}).value
          : ev

        /**
         * Emitted when the value changes
         * @event input
         * @type {string}
         */
        if (value !== this.value) this.$emit('input', value)
      }

      return { ...this.$listeners, input: emitInput, update: emitInput }
    },
    inputAttrs () {
      return { ...this.$attrs, ...(this.isMoney ? this.moneyMask : {}) }
    }
  },

  methods: {
    checkKey (event) {
      if (this.isMoney && this.positiveOnly && event.key === '-')
        event.preventDefault() && event.stopPropagation()
    }
  }
}
</script>

<style lang="scss">
.c-input-abstract { resize: none; margin: 0; }
</style>
