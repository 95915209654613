<template>
  <transition-group
    v-if="group"
    v-bind="$attrs"
    :duration="groupDuration"
    class="c-transition"
    :name="groupName"
    @leave="leave"
  >
    <slot />
  </transition-group>

  <transition
    v-else
    v-bind="$attrs"
    :duration="duration"
    :name="name"
  >
    <!-- @slot The transition's contents (the element(s) to apply the transition on. ) -->
    <slot />
  </transition>
</template>

<script>

/**
 * A generic transition component.
 */
export default {
  name: 'CTransition',
  props: {
    /**
     * Uses the transition-group element instead of transition, use this
     * if you're applying a transition to a group of element, like a list
     * of cards for example.
     */
    group: Boolean,

    /**
     * Uses a scaling transition effect.
     */
    scale: Boolean,

    /**
     * The transition duration in miliseconds
     * with a default value of 500ms.
     */
    duration: {
      type: [String, Number],
      default: 500
    },

    /**
     * Set a carousel transition style. Usefull for switching images, for instance.
     * It takes a string as a argument to set the direction of the animation.
     */
    carousel: {
      type: String,
      default: '',
      validator: val => ['left', 'right', ''].includes(val)
    }
  },

  watch: {
    duration (val) {
      this.$el.parentElement.style.setProperty('--duration', `${val}ms`)
    }
  },

  mounted () {
    this.$el.parentElement.style.setProperty('--duration', `${this.duration}ms`)
  },

  computed: {
    name () {
      const carousel = () => {
        if (this.carousel === 'left') return 'carousel-left'
        if (this.carousel === 'right') return 'carousel-right'
        return 'default'
      }

      return this.group ? `group-${carousel()}` : carousel()
    },
    groupName () {
      if (this.scale) return 'scale'
      return 'group-default'
    },
    groupDuration () {
      if (this.scale) return 750
      return 500
    }
  },

  methods: {
    leave (element) {
      const height = parseInt(getComputedStyle(element).height, 10)
      const marginTop = parseInt(getComputedStyle(element).marginTop, 10)
      const marginBottom = parseInt(getComputedStyle(element).marginBottom, 10)

      element.style.marginTop = '-' + (height + marginTop + marginBottom) + 'px'
    }
  }
}
</script>

<style lang="scss">
.c-transition {
  & > .group-default-enter-active,
  & > .group-default-leave-active {
     transition: opacity var(--duration),
                 transform var(--duration) !important;
   }

  & > .group-default-leave-to {
    opacity: 0 !important;
    transform: translateY(200%) !important;
  }

  & > .group-default-enter {
    opacity: 0 !important;
    transform: translateY(100%) !important;
  }

  & > .group-default-move,
  & > .scale-move {
    transition: transform .5s,
                opacity .5s !important;
  }

  & > .scale-enter-active,
  & > .scale-leave-active {
     transition: margin-top .5s .25s,
                 opacity .5s,
                 transform .5s !important;
   }

  & > .scale-leave-to {
    opacity: 0 !important;
    transform: scale(0.5) !important;
  }

  & > .scale-enter {
    opacity: 0 !important;
    transform: scale(0.5) !important;
  }
}

.default-enter,
.default-leave-to {
  opacity: 0 !important;
}

.default-enter-active,
.default-leave-active,
.carousel-left-enter-active,
.carousel-left-leave-active,
.carousel-right-enter-active,
.carousel-right-leave-active {
  transition: opacity var(--duration),
              transform var(--duration);
}

.carousel-left-enter {
  opacity: 0 !important;
  transform: translateX(-50%) !important;
}

.carousel-left-leave-to {
  opacity: 0 !important;
  transform: translateX(50%) !important;
}

.carousel-right-enter {
  opacity: 0 !important;
  transform: translateX(50%) !important;
}

.carousel-right-leave-to {
  opacity: 0 !important;
  transform: translateX(-50%) !important;
}
</style>
