import * as types from '@store/types'
import axios from 'axios'
import Cookie from 'js-cookie'

const clearCookies = () => {
  const cookies = Cookie.get()
  Object.keys(cookies)
    .forEach(cookieName => Cookie.remove(cookieName, { path: '/', domain: process.env.VUE_APP_COOKIE_DOMAIN }))
}

const state = {
  captchaToken: ''
}

const mutations = {
  [types.LOGIN_UPDATE_CAPTCHA_TOKEN]: (state, captchaToken) => {
    state.captchaToken = captchaToken
  }
}

const actions = {
  [types.LOGIN_SIGN_IN]: async ({ commit, state, dispatch, getters }, { email, password, ci_login }) => {
    commit(types.LOGIN_UPDATE_BUSY)
    commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, '')
    clearCookies()

    await axios
      .post(`${process.env.VUE_APP_API}/api/v1/user/login`, {
        email,
        password,
        ci_login,
        captcha: state.captchaToken
      })
      .then(({ data = {} }) => {
        const domain = process.env.VUE_APP_COOKIE_DOMAIN

        // Set cookies
        Object.keys(data).forEach(n => Cookie.set(n, data[n], { domain }))

        if (data.cisession) window.location = getters[types.SETTINGS_REDIRECT]
        else commit(types.LOGIN_UPDATE_FEEDBACK, 'err')
      })
      .catch(error => {
        if (!!error.response && error.response.data.message === 'Invalid Credentials')
          commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, 'E-mail ou senha inválidos')
        else
          commit(types.LOGIN_UPDATE_FEEDBACK, 'err')

        commit(types.LOGIN_UPDATE_BUSY)
        dispatch(types.LOGIN_GET_CAPTCHA_TOKEN)
      })
  },
  [types.LOGIN_VALIDATE_RESET_TOKEN]: async ({ commit }, token) => {
    await axios
      .get(`${process.env.VUE_APP_API}/api/v1/user/hash/is-valid/${token}`)
      .then(({ data }) => { ((data || {}).valid
        ? commit(types.LOGIN_UPDATE_RESET_TOKEN, token)
        : commit(types.LOGIN_UPDATE_FEEDBACK, 'expired')) })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'expired') })
      .then(() => { commit(types.LOGIN_UPDATE_CONTENT, 'passwordReset') })
  },
  [types.LOGIN_SEND_EMAIL]: async ({ commit }, email) => {
    await axios
      .post(`${process.env.VUE_APP_API}/api/v1/user/reset`, { email })
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  },
  [types.LOGIN_RESET_PASSWORD]: async ({ commit, rootState }, { password, password_confirmation }) => {
    await axios
      .put(`${process.env.VUE_APP_API}/api/v1/user/reset`, {
        hash: rootState.base.resetToken,
        password,
        password_confirmation
      })
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  },
  [types.LOGIN_GET_CAPTCHA_TOKEN]: ({ commit }) => {
    /* eslint-disable no-undef */
    const getCaptcha = () => grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.VUE_APP_CAPTCHA_TOKEN, { action: 'login' })
        .then(async token => { commit(types.LOGIN_UPDATE_CAPTCHA_TOKEN, token) })
    })

    if (window.hasOwnProperty('grecaptcha'))
      getCaptcha()
    else
      setTimeout(getCaptcha, 2000)
  }
}

export default { state, mutations, actions }
