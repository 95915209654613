<template>
  <div :style="{ 'top': topPos }" class="c-header">
    <div :style="{ 'height': computedHeight }" class="content">
      <!-- @slot The main header content. -->
      <slot />
    </div>
  </div>
</template>

<script>

/**
 * Our main header/sub-header component.
 */
export default {
  name: 'CHeader',
  props: {
    /**
     * The height of the main header section.
     */
    height: {
      type: [String, Number],
      default: 70
    },

    /**
     * Whether to show or hide the main section of the menu
     * (doesn't hide the sub-menu section).
     */
    hide: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedHeight () {
      return `${+this.height}px`
    },
    topPos () {
      if (!this.hide) return 0
      else return `-${+this.height}px`
    }
  }
}
</script>

<style lang="scss">
.c-header {
  display: flex;
  flex-direction: column;
  width: 100%;

  position: absolute;
  z-index: $z-index-2;
  right: 0;
  left: 0;
  top: 0;

  will-change: top;
  transition: top .3s ease;

  box-shadow: $base-shadow;

  & > .content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    background: white;

    padding: 10px;

    border-bottom: $base-border;
  }

  & > .sub-menu {
    flex-shrink: 0;
    width: 100%;
    background: #FFF;

    height: 50px;

    @include responsive (xs-mobile, mobile) { height: 40px; }
  }
}
</style>
