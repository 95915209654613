<template>
  <c-input-container
    v-bind="$attrs"
    :validation="validation"
    :class="['c-input', { '-jumbo': jumbo, '-has-icon': icon, '-not-empty': notEmpty, '-textarea': textArea }]"
  >
    <c-icon
      v-if="icon"
      :icon="icon"
      :style="{ fill: iconColor }"
      class="left-icon"
    />

    <c-input-abstract
      :disabled="disabled"
      :text-area="textArea"
      :class="classes"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      ref="input"
    />

    <c-icon
      v-if="rightIcon && !(validation && jumbo)"
      :icon="rightIcon"
      :style="{ fill: iconColor }"
      class="right-icon"
    />
  </c-input-container>
</template>

<script>
import CInputContainer from '../CInputContainer'
import CInputAbstract from '../CInputAbstract'
import CIcon from '../CIcon'

/**
 * Our main input component.
 */
export default {
  name: 'CInput',
  components: { CInputContainer, CInputAbstract, CIcon },

  props: {

    /**
     * The icon to show on the left-side.
     */
    icon: String,

    /**
     * The icon to show in the right-side.
     */
    rightIcon: String,

    /**
     * The color of the icon(s).
     */
    iconColor: String,

    /**
     * Wheter the input has border-radius.
     */
    round: Boolean,

    /**
     * Wheter the input is opaque
     */
    opaque: Boolean,

    /**
     * Removes hover effect.
     */
    noHover: Boolean,

    /**
     * Disables the input.
     */
    disabled: Boolean,

    /**
     * Whether it is a textarea.
     */
    textArea: Boolean,

    /**
     * Jumbo style.
     */
    jumbo: Boolean,

    /**
     * The field validation message.
     */
    validation: String,

    /**
     * The value of the input
     */
    value: [String, Number]
  },

  computed: {
    classes () {
      return [
        'input',
        {
          '-has-icon': this.icon,
          '-has-right-icon': this.rightIcon,
          '-round': this.round,
          '-opaque': this.opaque,
          '-no-hover': this.noHover,
          '-disabled': this.disabled,
          '-textarea': this.textArea
        }
      ]
    },

    notEmpty () {
      return this.value || this.autoFilled
    }
  },

  data () {
    return { autoFilled: false }
  },

  methods: {
    handleAutofill ({ animationName }) {
      if (animationName === 'onAutoFillStart') this.autoFilled = true
      if (animationName === 'onAutoFillCancel') this.autoFilled = false
    }
  },

  mounted () {
    this.$nextTick(() => {
      const input = this.$refs.input
      input.$el.addEventListener('animationstart', this.handleAutofill, false)
    })
  },

  beforeDestroy () {
    const input = this.$refs.input
    input.$el.removeEventListener('animationstart', this.handleAutofill, false)
  }
}
</script>

<style lang="scss">
$icon-position: 8px;

.c-input {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: margin-bottom .3s;

  & > .left-icon, & > .right-icon {
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    fill: map-get($text-color, base-30);
    padding-right: 5px;
  }

  & > .right-icon { right: $icon-position; }
  & > .left-icon { left: $icon-position; }

  & > .input {
    outline: 0;
    font-size: 14px;
    border: $base-border;
    border-radius: 5px;
    color: map-get($text-color, base-80);
    transition: box-shadow .3s ease;
    -webkit-appearance: none;
    height: 100%;

    &::placeholder { color: map-get($text-color, base-30); }

    &:focus:not(.-no-hover) {
      border-color: rgba($primary-color, .35);
      @include hover();
    }

    &.-textarea { padding: 15px; }
    &:not(.-textarea) { height: 40px; text-indent: 20px; }

    &.-has-icon { text-indent: 35px; }
    &.-has-right-icon { padding-right: 40px; }
    &.-round { border-radius: 20px; border: $base-border; }
    &.-opaque { background-color: map-get($text-color, base-05) }
    &.-disabled {
      cursor: default;
      border: 1px solid map-get($text-color, base-01);
      background-color: map-get($text-color, base-05);
    }
  }

  & > .validation { display: block; }
  & > .jumbo-validation { display: none; }

  &.-validation > .input,
  &.-validation > .input:focus:not(.-no-hover) {
    border-color: rgba($negative-color, .35);
    @include hover($negative-color);
  }

  &.-jumbo {
    & > .left-icon { left: 20px; }
    & > .right-icon { right: 20px; }

    & > .input {
      padding-top: 14px;
      text-indent: 0;
      padding-right: 20px;
      padding-left: 20px;

      &.-textarea { padding-top: 27px; }
      &:not(.-textarea) { height: 60px; }
      &::placeholder { color: transparent; }
    }

    & > .label {
      top: 50%;
      transform: translateY(-50%);
      padding: 0 20px;
      font-family: $base-font-family;
      font-size: 14px;
      color: map-get($text-color, base-30);
      pointer-events: none;
      transition: top .3s, font-size .3s, transform .3s;
    }

    &.-textarea > .label { top: 30px; }

    &.-has-icon {
      & > .label { left: 25px; }
      & > .input { padding-left: 44px; }
    }

    &:focus-within > .label,
    &.-not-empty > .label {
      top: 14px;
      transform: translateY(0);
      font-size: 11px;
    }

    &.c-input { margin-top: 0; }

    & > .validation { display: none; }
    & > .jumbo-validation { display: block; }

    &.-validation {
      & > .input {
        $background: (
          light: rgba(map-get($negative-color-map, light), 0.2),
          dark:  rgba(map-get($negative-color-map, dark), 0.2)
        );
        border-color: rgba($negative-color, .2);
        background: set-linear-gradient(315deg, $background);
        box-shadow: none;
        color: map-get($negative-color-map, light);
        padding-right: 50px;
      }
      & > .input:focus:not(.-no-hover) { @include hover($negative-color); }
      & > .label { color: map-get($negative-color-map, light); }
      & > .left-icon,
      & > .right-icon { fill: map-get($negative-color-map, light); }
    }

    // Hack that emmits an animation event to identify inputs autofill
    & > .input:-webkit-autofill { animation-name: onAutoFillStart; }
    & > .input:not(:-webkit-autofill) { animation-name: onAutoFillCancel; }
    @keyframes onAutoFillStart {}
    @keyframes onAutoFillCancel {}
  }

  @include xs-mobile {
    &.-jumbo {
      &:not(.-textarea) {
        height: 50px;
        & > .input { height: 50px;}
      }

      & > .validation { display: block; }
      & > .jumbo-validation { display: none; }

      & > .label { padding: 0 15px; }

      &:focus-within > .label,
      &.-not-empty > .label { top: 6px; }

      & > .input {
        padding: {
          top: 10px;
          left: 15px;
          right: 15px;
        }
      }
    }
  }
}
</style>
