<template>
  <router-view />
</template>

<script>
import MediaQueryRoot from '@mixins/MediaQueryRoot'

export default {
  mixins: [ MediaQueryRoot ]
}
</script>

<style src="./styles/index.scss" lang="scss" />
