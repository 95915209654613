<template>
  <icon
    v-if="!isLoading"
    :icon="icon"
    :width="'' + size"
    :height="'' + size"
    class="c-icon"
    v-bind="$attrs"
  />
</template>

<script>
import Icon from 'vue-svgicon'

export default {
  name: 'CIcon',
  components: { Icon },

  props: {
    size: {
      type: [String, Number],
      default: 20
    },
    icon: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    loadIcon () {
      if (!this.icon) return

      const icon = require('vue-svgicon')
      const path = require('../../../assets/icons/' + this.icon + '.js')

      this.isLoading = true
      icon.register(path.default)

      /* eslint-disable */
      import('../../../assets/icons/' + this.icon).then(() => {
        this.isLoading = false
        this.$emit('loaded-icon')
      })
    }
  },

  created () {
    this.loadIcon()
  },
}
</script>

<style lang="scss">
.c-icon { display: inline-block; }
</style>
