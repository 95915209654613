<script>
import Border from './svg/Border'
import Check from './svg/Check'

/**
 * A wrapper component for selectable elements.
 */
export default {
  name: 'CSelectable',
  props: {
    /**
     * Sets the state of the selectable's checkbox.
     */
    selected: Boolean,

    /**
     * The color of the icon in it's inactive state.
     */
    inactiveColor: {
      type: String,
      default: 'rgba(255,255,255,0.50)',
      validator: color => typeof color === 'string' && !!color.length
    },

    /**
     * The color of the border of the checkbox in it's inactive state.
     */
    inactiveBorder: {
      type: String,
      default: 'rgba(18,30,72,0.10)',
      validator: color => typeof color === 'string' && !!color.length
    },

    /**
     * The background color when the item is active.
     */
    color: {
      type: [String, Array],
      default: () => ['#3CE797', '#37CECD'],
      validator: color => (typeof color === 'string' || Array.isArray(color)) && !!color.length
    },

    /**
     * The color of the icon of the checkbox when the element is active
     */
    activeIconColor: {
      type: String,
      default: '#FFF'
    },

    /**
     * The color of the icon of the checkbox in it's hover state.
     */
    hoverIconColor: {
      type: String,
      validator: color => typeof color === 'string' && !!color.length
    },

    /**
     * The color of the border of checkbox in it's hover state.
     */
    hoverBorderColor: {
      type: String,
      validator: color => typeof color === 'string' && !!color.length
    },

    /**
     * The position of the checkbox.
     */
    position: {
      type: String,
      default: 'right',
      validator: position => {
        const matchedPosition = direction => direction === position

        return ['left', 'right'].some(matchedPosition)
      }
    },

    /**
     * Internal padding of the element.
     */
    padding: [String, Number],

    /**
     * Disables the component's actions.
     */
    disabled: Boolean
  },

  computed: {
    classes () {
      return ['c-selectable', {
        '-left': this.checkPosition('left'),
        '-right': this.checkPosition('right'),
        '-has-slot': this.hasSlot,
        '-is-active': this.selected,
        '-disabled': this.disabled
      }]
    },
    colorStyle () {
      const isValidArray = Array.isArray(this.color) && !!this.color.length

      const background = (isValidArray && 'linear-gradient(105deg, ' + this.color.join(`, `) + `)`) || this.color

      // set color to border and ícon
      const primaryColor = (isValidArray && this.color[0]) || this.color

      return {
        '--inactive-background': this.inactiveColor,
        '--inactive-border': this.inactiveBorder,
        '--hover-border-color': this.hoverBorderColor,
        '--background': background,
        '--primary': primaryColor,
        '--icon-color': this.activeIconColor
      }
    },
    paddingFix () {
      const iconSize = 25
      const padding = (+this.padding || 0) + iconSize + 'px'

      return {
        paddingLeft: this.getSpacement('left', padding),
        paddingRight: this.getSpacement('right', padding)
      }
    },
    paddingStyle () {
      const padding = this.padding + 'px'

      return {
        marginRight: this.getSpacement('right', padding),
        marginLeft: this.getSpacement('left', padding)
      }
    },
    hasSlot () {
      return !!this.$slots.default
    }
  },

  methods: {
    checkPosition (position) {
      return this.position === position
    },
    getSpacement (position, padding) {
      return this.checkPosition(position) && this.hasSlot && padding
    }
  },

  render (h) {
    const border = h(Border, {
      attrs: {
        selected: this.selected,
        hoverBorderColor: this.hoverBorderColor
      }
    })
    const activeCheck = h(Check, {
      attrs: {
        class: 'c-selectable-check-active',
        selected: true,
        activeIconColor: this.activeIconColor
      }
    })
    const hoverCheck = h(Check, {
      attrs: {
        selected: false,
        hoverIconColor: this.hoverIconColor
      }
    })
    const inactiveBorder = h('div', {
      attrs: { class: 'inactive-border' }
    })
    const selectable = h('div', {
      class: 'selectable',
      style: [this.paddingStyle, this.colorStyle]
    }, [ inactiveBorder, border, hoverCheck, activeCheck ])

    return h('div',
      {
        class: this.classes,
        style: this.paddingFix,
        on: {
          click: () => {
            /**
             * Emitted when the user clicks the element or it's children.
             * @event
             */
            this.$emit('toggle')
          }
        }
      }, [ selectable, this.$slots.default ]
    )
  }
}
</script>

<style lang="scss">
.c-selectable {
  position: relative;
  display: inline-block;
  cursor: pointer;

  & > .selectable {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: box-shadow .3s;

    & > .inactive-border {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-sizing: border-box;
      border-style: solid;
      border-width: 1px;
      border-color: var(--inactive-border);
      transition: opacity .3s;
    }

    & > .c-selectable-border,
    & > .c-selectable-check,
    & > .c-selectable-check-active {
      opacity: 0;
      transition: opacity .3s;
    }
  }

  &:not(.-is-active) {
    & > .selectable {
      background: var(--inactive-background);
    }

    &:hover > .selectable {
      & > .inactive-border { opacity: 0; }
      & > .c-selectable-border,
      & > .c-selectable-check { opacity: 1; }
    }
  }

  &.-is-active > .selectable {
    background: var(--background);
    background-repeat: no-repeat;
    box-shadow: 0 2px 15px -2px var(--primary);

    & > .c-selectable-border,
    & > .c-selectable-check,
    & > .inactive-border { opacity: 0; }
    & > .c-selectable-check-active { opacity: 1; }
  }

  &:not(.-has-slot) {
    min-width: 25px;
    min-height: 25px;
    display: inline-block;
  }

  &.-has-slot {
    &.-left > .selectable { left: 0; }
    &.-right > .selectable { right: 0; }
  }

  &.-disabled {
    cursor: default;
    pointer-events: none;

    &.-is-active > .selectable {
      background: lighten(map-get($disabled-color-map, light), 42%);
      box-shadow: 0 0px 3px 0px lighten(map-get($disabled-color-map, light), 42%);
      border: none;
    }
  }
}
</style>
