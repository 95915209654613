<template>
  <c-link
    ref="btn"
    :class="classes"
    :style="buttonStyles"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
    @click.native="onClick()"
  >
    <c-icon
      v-if="icon"
      :icon="icon"
      :size="iconSize"
      class="icon"
    />

    <span v-if="$slots.default" class="text">
      <!-- @slot The content of the button -->
      <slot />
    </span>

    <transition name="loading">
      <div class="loader" v-if="loading">
        <div class="dot-loader"></div>
        <div class="dot-loader dot-loader--2"></div>
        <div class="dot-loader dot-loader--3"></div>
      </div>
    </transition>

    <div class="border -one" />
    <div class="border -two" />
    <div class="border -three" />
    <div class="border -four" />
  </c-link>
</template>

<script>
import CIcon from '../CIcon'
import CLink from '../CLink'

/**
 * The standard button used throught convenia projects.
 */
export default {
  name: 'CButton',
  components: { CIcon, CLink },

  props: {
    /**
     * The height of the button.
     */
    size: {
      type: [String, Number],
      default: 40,
      validator: size => typeof +size === 'number' && +size > 0
    },

    /**
     * The icon to show in the right side (or in the center if no text)
     * of the button. Optional.
     */
    icon: {
      type: String,
      default: ''
    },

    /**
     * The size of the icon.
     */
    iconSize: {
      type: [String, Number],
      default: '20'
    },

    /**
     * Makes the background slightly lighter when disabled. Use this prop
     * when placing the CButton on an element with dark bg with opacity.
     */
    alternativeDisabled: Boolean,

    /**
     * Makes the button grow to fill the entire width of the container.
     */
    fullWidth: Boolean,

    /**
     * Disables the button.
     */
    disabled: Boolean,

    /**
     * A large button with bigger padding and font-size.
     */
    jumbo: Boolean,

    /**
     * Sets the bg-color of the button to the primary convenia color.
     */
    primary: Boolean,

    /**
     * Sets the bg-color of the button to the sucess convenia color.
     */
    success: Boolean,

    /**
     * Sets the bg-color of the button to the error convenia color.
     */
    error: Boolean,

    /**
     * Makes the button's appearance flat, with a transparent bg.
     */
    flat: Boolean,

    /**
    * Makes the button's appearance flat, with a blur bg.
    */
    flatBlur: Boolean,

    /**
     * Displays a loader (spinner) on the button
     */
    loading: Boolean,

    /**
     * Sets the bg-color of the button to white.
     */
    white: Boolean,

    /**
     * Makes the button have the same appearance of a link.
     */
    ghost: Boolean,

    /**
     * Makes the background grey ($text-color, base-30).
     */
    grey: Boolean,

    /**
     * Makes the background translucent rgba(#FFF, .3)
     */
    translucent: Boolean
  },

  data () {
    return {
      clicked: false,
      ratio: 1
    }
  },

  computed: {
    classes () {
      return [ 'c-button',
        {
          '-disabled': this.disabled,
          '-alternative-disabled': this.alternativeDisabled,
          '-jumbo': this.jumbo,
          '-has-icon': this.icon && this.$slots.default,
          '-icon-only': !this.$slots.default,
          '-full-width': this.fullWidth,
          '-primary': this.primary ||
            (!this.success && !this.error && !this.white && !this.ghost),
          '-success': this.success,
          '-error': this.error,
          '-white': this.white,
          '-ghost': this.ghost,
          '-flat': this.flat,
          '-flat-blur': this.flatBlur,
          '-grey': this.grey,
          '-translucent': this.translucent,
          '-loading': this.loading,
          '-clicked': this.clicked
        }
      ]
    },

    buttonSize () {
      return this.jumbo ? 60 : this.size
    },

    buttonStyles () {
      return !this.ghost ? {
        height: `${+this.buttonSize}px`,
        minHeight: `${+this.buttonSize}px`,
        minWidth: `${+this.buttonSize}px`,
        borderRadius: this.fullWidth ? '0px' : `${+this.buttonSize}px`
      } : { }
    }
  },

  methods: {
    onClick () {
      if (this.clicked) return
      this.clicked = true
      setTimeout(() => { this.clicked = false }, 500)
    }
  }
}
</script>

<style lang="scss">
.c-button {
  position: relative;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $primary-color;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  padding: 0 40px;
  text-align: center;
  transition: background-color .3s,
              opacity .3s,
              box-shadow .3s,
              border .3s;

  // Moving borders animation
  @include pulse-animation(26px);

  & > .border {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: inherit;
    border: 1px black solid;
    opacity: 0;
    animation-play-state: paused;
    flex: 1;
  }

  &.-clicked {
    & > .border { animation-play-state: running; }
    & > .-one { animation: pulse-1 .3s linear forwards; }
    & > .-two { animation: pulse-2 .3s 60ms linear forwards; }
    & > .-three { animation: pulse-3 .3s 120ms linear forwards; }
    & > .-four { animation: pulse-4 .3s .180ms linear forwards; }
  }

  // Inner element for the box shadow
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 93%;
    height: 75%;
    border-radius: inherit;
    opacity: 0.6;
    transform: translateX(-50%) translateY(-50%) translateZ(-1px);
    transition: box-shadow .3s, opacity .3s;
    flex: 1;
  }

  // bright layer for hover effect
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    background: #fff;
    opacity: 0;
    transition: opacity .3s;
    flex: 1;
  }

  // hover effects
  &:hover, &:focus {
    &::after { opacity: 0.1; }
  }

  &:active::after { opacity: 0; }

  & > .icon {
    fill: rgba(#FFF, .5);
    flex: 1;
    flex-shrink: 0;
    transition: fill .3s ease, filter .3s;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .3));
  }

  &.-has-icon {
    & > .icon { left: 20px; }
    & > .text { padding-left: 10px; }
  }

  & > .text {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    font-family: $title-font-family;
    color: #FFF;
    line-height: 14px;
    white-space: nowrap;
    flex: 1;

    text-shadow: $base-text-shadow;
    transition: color 300ms ease, opacity .3s;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & > .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex: 1;

    @include xs-mobile {
      transform: translate(-50%, -50%) scale(0.75);
    }

    & > .dot-loader {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: inline-block;
      margin: 0 2.5px;
      animation: 1.2s grow ease-in-out infinite;
    }

    & > .dot-loader--2 {
      animation: 1.2s grow ease-in-out infinite 0.15555s;
    }
    & > .dot-loader--3 {
      animation: 1.2s grow ease-in-out infinite 0.3s;
    }
  }

  &.-jumbo {
    padding: 0 50px;
    & > .text { font-size: 13px; }
  }

  &.-icon-only {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    & > .icon { fill: #FFF; }
  }

  &.-primary {
    background: set-linear-gradient(135deg, $primary-color-map);
    & > .border { border-color: $primary-color; }
    &::before { box-shadow: 0 1px 20px 0 $primary-color; }
    &:hover::before,
    &:focus::before { box-shadow: 0 5px 30px 5px $primary-color; }
  }

  &.-success {
    background: set-linear-gradient(135deg, $positive-color-map);
    & > .border { border-color: $positive-color; }
    &::before { box-shadow: 0 1px 20px 0 map-get($positive-color-map, dark); }
    &:hover::before,
    &:focus::before { box-shadow: 0 5px 30px 5px map-get($positive-color-map, dark); }
  }

  &.-error {
    background: set-linear-gradient(135deg, $negative-color-map);
    & > .border { border-color: $negative-color; }
    &::before { box-shadow: 0 1px 20px 0 $negative-color; }
    &:hover::before,
    &:focus::before { box-shadow: 0 5px 30px 5px $negative-color; }
  }

  &.-white {
    background: #FFF;
    & > .border { border-color: map-get($text-color, base-50); }
    & > .icon { fill: map-get($text-color, base-50); }
    & > .text { color: map-get($text-color, base-50); }
    &::before { box-shadow: 0 1px 20px 0 map-get($text-color, base-50); }
    &:hover::before,
    &:focus::before { box-shadow: 0 5px 30px 5px map-get($text-color, base-50); }
  }

  &.-grey {
    background: map-get($text-color, base-30);
    box-shadow: 0 3px 10px -4px map-get($text-color, base-30);
    &::before { display: none; }
    & > .border { display: none; }
  }

  &.-translucent {
    background: rgba(#FFF, .3);

    & > .text { color: rgba(#FFF, .8); }
    & > .icon { fill: rgba(#FFF, .8); }

    &::before, &::after { display: none; }
    & > .border { display: none; }

    &:hover:not(.-disabled) { background: rgba(#FFF, .5); }
  }

  &.-flat {
    background: transparent;
    border: 1px solid map-get($text-color, base-10);

    &::before { display: none; }
    & > .border { border-color: $primary-color; }
    & > .icon { fill: map-get($text-color, base-50); filter: unset; }
    & > .text { color: map-get($text-color, base-50); text-shadow: unset; }
    & > .loader svg {
      filter: unset;
      path { fill: map-get($text-color, base-50); }
    }

    &:hover, &:focus {
      border: 1px solid $primary-color;
      box-shadow:
        0px 0px 3px 0px rgba($primary-color, 0.55),
        inset 0px 0px 3px 0px rgba($primary-color, 0.55);

      & > .icon { fill: map-get($text-color, base-80); }
      & > .text { color: map-get($text-color, base-80); }
      & > .loader svg path { fill: map-get($text-color, base-80); }
    }
  }

  &.-flat-blur {
    border: transparent;
    background-color: map-get($text-color, base-10);

    &::before { display: none; }
    & > .border { border-color: 1px solid rgba(#FFF, .3); }
    & > .icon { fill: map-get($text-color, base-50); filter: unset; }
  }

  &.-flat.-success {
    & > .border { border-color: $positive-color; }

    &:hover, &:focus {
      border: 1px solid $positive-color;
      box-shadow:
        0px 0px 3px 0px rgba($positive-color, 0.55),
        inset 0px 0px 3px 0px rgba($positive-color, 0.55);
    }
  }

  &.-flat.-error {
    & > .border { border-color: $negative-color; }

    &:hover, &:focus {
      border: 1px solid $negative-color;
      box-shadow:
        0px 0px 3px 0px rgba($negative-color, 0.55),
        inset 0px 0px 3px 0px rgba($negative-color, 0.55);
    }
  }

  &.-flat.-white {
    border: 1px solid rgba(#FFF, .3);

    & > .border { border-color: rgba(#FFF, .3); }
    & > .icon { fill: rgba(#FFF, .5); }
    & > .text { color: rgba(#FFF, .5); }
    & > .loader svg path { fill: rgba(#FFF, .5); }

    &:hover, &:focus {
      border: 1px solid rbga(#FFF, .5);
      box-shadow:
        0px 0px 3px 0px rgba(#FFF, 0.55),
        inset 0px 0px 3px 0px rgba(#FFF, 0.55);

      & > .icon { fill: rgba(#FFF, .8); }
      & > .text { color: rgba(#FFF, .8); }
      & > .loader svg path { fill: rgba(#FFF, .8); }
    }
  }

  &.-flat-blur.-white {
    & > .icon { fill: rgba(#FFF, .8); }
    & > .border { border-color: rgba(#FFF, .8); }

    &:hover, &:focus {
      border: 1px solid #FFF;
      background-color: map-get($text-color, base-20);
      box-shadow:
        0px 0px 3px 0px #FFF,
        inset 0px 0px 3px 0px #FFF;

      & > .icon { fill: #FFF; }
    }
  }

  &.-flat.-disabled {
    border: 1px solid map-get($text-color, base-10);
    background: transparent;

    & > .icon { fill: map-get($text-color, base-10); filter: unset; }
    & > .text { color: map-get($text-color, base-10); text-shadow: unset; }

    &:hover, &:focus {
      border: 1px solid map-get($text-color, base-10);
      box-shadow: none;

      & > .icon { fill: map-get($text-color, base-10); }
      & > .text { color: map-get($text-color, base-10); }
    }
  }

  &.-full-width {
    width: 100%;
    border-radius: 0;
    &::before { display: none; }
    & > .border { display: none; }
  }

  &.-ghost {
    background-color: transparent;
    border: none;
    padding: 0;

    &::before, &::after, & > .border { display: none; }
    & > .text { color: $base-text-color; text-shadow: unset; }
    & > .icon { fill: $base-text-color; }
    & > .loader svg { filter: unset; path { fill: $base-text-color; } }
  }

  &.-disabled {
    cursor: default;
    background: map-get($text-color, base-10);

    &::before, &::after { opacity: 0; }

    & > .icon {
      fill: map-get($text-color, base-10);
      filter: unset;
    }

    &.-alternative-disabled {
      background: rgba(#FFF, .1);
      & > .icon {fill: rgba(#FFF, .3); }
      & > .text { color: rgba(#FFF, .3); }
    }

    & > .text {
      color: map-get($text-color, base-30);
      text-shadow: unset;
    }
  }

  &.-loading {
    pointer-events: none;
    & > .text, .icon { visibility: hidden; }
  }

  // loading transitions
  & > .loading-enter-active { transition: opacity .2s .2s; }

  & > .loading-leave-active { transition: opacity .2s; }

  & > .loading-enter,
  & > .loading-leave-to { opacity: 0; }

  // loading animation
  @keyframes grow {
    0%, 40%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }

  @include ie {
    // check if ie box-shadow is working properly!!
    // &::before { background: none !important; }
  }
}
</style>
