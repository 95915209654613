<template>
  <form
    class="c-form"
    autocomplete="off"
    :name="name"
    @submit.prevent="submitForm"
  >
    <div class="fields">
      <slot>
        <template v-for="(field, fieldName) in fields">
          <component
            v-show="!isFieldHidden(fieldName)"
            :is="getFieldComponent(field.type)"
            :key="`${fieldName}-${attempts}`"
            :class="['field', field.type]"
            :name="fieldName"
            :label-left="labelLeft"
            :disabled="isFieldDisabled(fieldName)"
            :validation="getValidationMsg(fieldName)"
            :options="getFieldOptions(fieldName)"
            :value="getFieldValue(fieldName)"
            :force-tooltip="forceTooltip(fieldName)"
            :clear-tooltip="clearTooltip"
            v-bind="field"
            @add="addMultiSelectItem(fieldName, $event)"
            @remove="removeMultiSelectItem(fieldName, $event)"
            @input="updateField(fieldName, $event)"
          />
        </template>
      </slot>
    </div>

    <div class="actions">
      <slot name="actions">
        <c-button primary class="action">
          Salvar
        </c-button>
      </slot>
    </div>
  </form>
</template>

<script>
import CInput from '../CInput'
import CButton from '../CButton'

import is from '@helpers/is'
import FormValidator from 'vue-convenia-validator'

export default {
  name: 'CForm',
  mixins: [ FormValidator ],

  components: {
    CInput,
    CButton
  },

  props: {
    /**
     * The form fields.
     */
    fields: {
      type: [Object],
      required: true
    },

    /**
     * The form name.
     */
    name: {
      type: String,
      default: 'formData'
    },

    /**
     * Whether the form is loading.
     */
    loading: Boolean,

    /**
     * Disables the form.
     */
    disabled: Boolean,

    /**
     * Moves all of the input's labels to the left side.
     */
    labelLeft: Boolean,

    /**
     * Removes input and blur listeners from all inputs, making so that
     * form validation only happens on submit.
     */
    noListeners: Boolean
  },

  validatorOptions: vm => ({
    noListeners: vm.noListeners
  }),

  data () {
    return {
      [this.name]: {},
      dynamicFields: [],
      attempts: 0,
      clearTooltip: false
    }
  },

  computed: {
    firstFieldWithError () {
      const validations = this.$validations
      const validationsKeys = Object.keys(validations)
      const firstError = validationsKeys.find(key => !validations[key].valid)

      return firstError
    }
  },

  methods: {
    isFieldHidden (fieldName) {
      const field = this.fields[fieldName]

      if (is(field.hide, 'Function')) return field.hide(this[this.name])

      return field.hide
    },

    getFieldComponent (type) {
      const typeToComponent = {
        'text': 'c-input',
        'password': 'c-input'
      }

      return typeToComponent[type] || 'c-input'
    },

    addMultiSelectItem (fieldName, item) {
      const fieldValues = [ ...(this[this.name][fieldName] || []) ]
      fieldValues.push(item)

      this[this.name][fieldName] = fieldValues
    },

    removeMultiSelectItem (fieldName, item) {
      const field = this.fields[fieldName]

      const fieldValues = [ ...(this[this.name][fieldName] || []) ]
      const itemIndex = fieldValues.findIndex((fieldItem) => {
        return item[field.trackBy] === fieldItem[field.trackBy]
      })

      fieldValues.splice(itemIndex, 1)

      this[this.name][fieldName] = fieldValues
    },

    getValidationMsg (fieldName) {
      const fieldDef = this.fields[fieldName]
      const fieldFlags = this.$validations[fieldName] || {}
      const fieldErrors = (fieldFlags.errors || [])

      return fieldErrors.length || fieldDef.forceError
        ? fieldDef.validationMsg || fieldErrors[0] || ''
        : ''
    },

    getFieldValue (fieldName) {
      const field = this.fields[fieldName]

      if (is(field.value, 'Function')) {
        const value = field.value(this[this.name])
        this[this.name][fieldName] = value
        return value
      }

      return this[this.name][fieldName]
    },

    getFieldOptions (fieldName) {
      const field = this.fields[fieldName] || {}

      return field.optionsFilter
        ? field.optionsFilter(this[this.name], field.options)
        : field.options
    },

    forceTooltip (fieldName) {
      if (this.attempts === 0) return false

      return this.firstFieldWithError === fieldName
    },

    isFieldDisabled (fieldName) {
      const field = this.fields[fieldName] || {}
      const fieldOptions = field.options && this.getFieldOptions(fieldName)

      if (is(field.isDisabled, 'Function'))
        return field.isDisabled(this[this.name])

      return field.disabled || (!!field.options && !(fieldOptions || []).length)
    },

    updateField (fieldName, value) {
      const field = this.fields[fieldName] || {}

      if (field.onInput) field.onInput(this[this.name], value, this)

      this[this.name][fieldName] = value
    },

    submitForm () {
      if (this.noListeners && this.attempts === 0)
        this.bindDynamicFieldListeners()

      const isValid = this.$validator.validateAll()

      if (isValid) this.$emit('submit', this[this.name])
      else this.attempts++
    },

    setDynamicFields () {
      this.dynamicFields = Object.keys(this.fields)
        .filter(fieldName => is(this.fields[fieldName].validation, 'Function'))
        .map(fieldName => ({ ...this.fields[fieldName], name: fieldName }))

      if (this.noListeners) return

      this.bindDynamicFieldListeners()
    },

    bindDynamicFieldListeners () {
      const reEvaluator = (data) => {
        this.dynamicFields.forEach(({ name, ...field }) => {
          const newRule = field.validation(data, this.$validations)

          this.$validator.setFieldRule({ name, scope: this.name }, newRule)
        })
      }

      if (this.dynamicFields.length) {
        reEvaluator(this.name)
        this.$watch(this.name, reEvaluator, { deep: true })
      }
    },

    handleClick () {
      this.clearTooltip = true
    }
  },

  created () {
    const reduceToValue = (entity, key, ignoreEmpty) => Object.keys(entity)
      .reduce((acc, propName) => ({
        ...acc,
        ...(!(entity[propName] || {})[key] && ignoreEmpty
          ? {}
          : { [propName]: (entity[propName] || {})[key] })
      }), {})

    const validations = reduceToValue(this.fields, 'validation', true)
    this[this.name] = reduceToValue(this.fields, 'value')
    this.$validator.init({ [this.name]: validations })
    this.setDynamicFields()
  },

  watch: {
    firstFieldWithError (val) {
      this.clearTooltip = false
    },
    attempts (val) {
      this.clearTooltip = false
    }
  },

  mounted () {
    document.addEventListener('click', this.handleClick)
    this.$validator.validateAll()
  },

  beforeDestroy () {
    document.removeEventListener('click', this.handleClick)
  }
}
</script>

<style lang="scss">
.c-form {
  & > .fields {
    & > .field:not(:last-child):not(.-validation):not(.check):not(.multi-check):not(.-jumbo) {
      padding-bottom: 20px;
    }

    & > .field.check { margin-bottom: 20px; }

    & > .field.radio.-validation:not(:last-child) { margin-bottom: 62px; }

    & > .field.-jumbo {
      &:not(:last-child) { margin-bottom: 20px; }
      &.-validation.select:not(:last-child) { margin-bottom: 42px; }
      &:not(.radio) { margin-top: 0; }
    }

    & > .field[type="hidden"] { display: none; }

    & > .multi-select.-validation { padding-bottom: 30px; }

    & > .multi-check, & > .radio {
      & > .label { position: static; }
    }
  }

  & > .actions {
    display: flex;
    justify-content: flex-end;

    margin-top: 40px;

    & > .action {
      flex: 1 1;
      max-width: 180px;
    }
  }

  @include xs-mobile {
    & > .fields > .field.-jumbo.-validation {
      &.text, &.password { margin-bottom: 42px; }
    }
  }
}
</style>
