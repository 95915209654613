export const rh = {
  colorA: '#BC4CF7',
  colorB: '#7873EE',
  redirectUrl: process.env.VUE_APP_REDIRECT,
  apiUrl: process.env.VUE_APP_LOGIN_API,
  loginType: 'mfa',
  appType: 'rh'
}

export const bpo = {
  colorA: '#1E3C72',
  colorB: '#121E48',
  redirectUrl: 'http://bpo.developer.convenia.site',
  apiUrl: 'http://api.bpo.developer.convenia.site',
  loginType: 'oAuth2',
  appType: 'bpo'
}
