/**
 * Check value's constructor name.
 * @param {*} value
 * @param {string} constructor
 * @returns {boolean}
 */

export const is = (value, constructor) => {
  return Object.prototype.toString.call(value) === `[object ${constructor}]`
}

export default is
