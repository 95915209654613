<template>
  <svg class="c-selectable-border" width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
    <defs>
      <linearGradient id="c-selectable-linearGradient-1" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop stop-color="#3CE798" offset="0%" />
        <stop stop-color="#37CFCC" offset="100%" />
      </linearGradient>
      <filter id="c-selectable-filter-2" x="-12.0%" y="-12.0%" width="124.0%" height="124.0%" filterUnits="objectBoundingBox">
        <feGaussianBlur stdDeviation="1" in="SourceGraphic" />
      </filter>
    </defs>
    <g class="border" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-211.000000, -20.000000)">
        <g transform="translate(213.000000, 22.000000)">
          <circle stroke="#121E48" opacity="0.1" cx="12.5" cy="12.5" r="12" />
          <circle :stroke="circleStroke" opacity="0.9" filter="url(#c-selectable-filter-2)" cx="12.5" cy="12.5" r="12" />
          <circle :stroke="circleStroke" cx="12.5" cy="12.5" r="12" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selected: Boolean,
    hoverBorderColor: String
  },

  computed: {
    circleStroke () {
      return this.hoverBorderColor || 'url(#c-selectable-linearGradient-1)'
    }
  }
}
</script>

<style lang="scss">
.c-selectable-border {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.2);
}
</style>
