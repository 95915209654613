import * as types from '@store/types'
import Cookie from 'js-cookie'
import axios from 'axios'
import { xsrfTokenInterceptor } from '@modules/request'

const http = axios.create({
  baseURL: `${process.env.VUE_APP_LOGIN_API}/api/v1`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
})

http.interceptors.request.use(xsrfTokenInterceptor)

const noAuthHttp = axios.create({
  baseURL: `${process.env.VUE_APP_LOGIN_API}/api/v1`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false
})

const errFeedbacks = {
  generic: 'Não foi possível autenticar o usuário',
  credentials: 'E-mail ou senha inválidos',
  blocked: [
    'Usuário bloqueado!',
    'Muitas tentativas de senha incorreta.',
    'Tente novamente mais tarde.'
  ].join(' ')
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++)
    await callback(array[index], index, array)
}

const state = {
  captchaToken: ''
}

const mutations = {
  [types.LOGIN_UPDATE_CAPTCHA_TOKEN]: (state, token) => {
    state.captchaToken = token
  }
}

const actions = {
  [types.LOGIN_SIGN_IN_REDIR]: async (ctx, { data, keepConnected }) => {
    const redir = process.env.VUE_APP_REDIRECT
    const domain = process.env.VUE_APP_COOKIE_DOMAIN

    await asyncForEach(Object.entries(data || {}), async ([cookieName, cookieVal]) => {
      Cookie.set(cookieName, cookieVal, { domain })
    })

    if (keepConnected) {
      // 86400 = seconds in a day
      const expires = Math.round(data.expires_in / 86400)
      Cookie.set('refresh_token', data.refresh_token,
        { domain, expires })
    }

    window.location = redir
  },
  [types.LOGIN_SIGN_IN_ERR]: ({
    getters,
    commit,
    dispatch
  }, err) => {
    const response = (err || {}).response
    const status = (response || {}).status
    const message = ((response || {}).data || {}).message
    const wrongCredentials = [
      'These credentials do not match our records.'
    ].includes(message)

    const spitFeedback = (feedback = 'generic') => commit(
      types.LOGIN_UPDATE_ERROR_FEEDBACK,
      errFeedbacks[feedback] || '')

    if (wrongCredentials)
      spitFeedback('credentials')
    else if (!!response && status === 429)
      spitFeedback('blocked')
    else
      spitFeedback()

    if (getters[types.LOGIN_BUSY])
      commit(types.LOGIN_UPDATE_BUSY)

    dispatch(types.LOGIN_GET_CAPTCHA_TOKEN)
  },

  [types.LOGIN_SIGN_IN]: async ({
    state,
    commit,
    dispatch
  }, actionData) => {
    commit(types.LOGIN_UPDATE_BUSY)
    commit(types.LOGIN_UPDATE_ERROR_FEEDBACK, '')

    const payload = {
      ci_login: true,
      email: actionData.email,
      password: actionData.password,
      captcha: state.captchaToken
    }

    return http
      .post('login', payload)
      .then(({ data }) => {
        if ((data || {}).two_factor) {
          commit(types.LOGIN_UPDATE_BUSY)
          return { redirect: true }
        }

        dispatch(
          types.LOGIN_SIGN_IN_REDIR,
          {
            data,
            keepConnected: actionData.keep_connected
          }
        )

        return {}
      })
      .catch(err => dispatch(types.LOGIN_SIGN_IN_ERR, err))
  },

  [types.LOGIN_2FA]: async ({ commit, dispatch }, code) => {
    commit(types.LOGIN_UPDATE_BUSY)

    const success = ({ data }) =>
      dispatch(types.LOGIN_SIGN_IN_REDIR, { data, keepConnected: false })
    const failure = () =>
      commit(types.LOGIN_UPDATE_BUSY) ||
      commit(types.LOGIN_UPDATE_ERROR_FEEDBACK,
        'Código de confirmação expirado ou inválido')

    return http
      .post('two-factor-challenge', { code })
      .then(success)
      .catch(failure)
  },

  [types.LOGIN_RESEND_CODE]: async ({ dispatch }) => {
    return http
      .post('two-factor-secret-key/send', {})
      .catch(err => dispatch(types.LOGIN_SIGN_IN_ERR, err))
  },

  [types.LOGIN_SOCIALITE]: async ({ commit, dispatch }) => {
    const success = ({ data }) => {
      if ((data || {}).link)
        window.location = (data || {}).link
      else {
        dispatch(
          types.LOGIN_SIGN_IN_ERR,
          new Error('Não foi possível realizar o login.')
        )
      }

      commit(types.LOGIN_UPDATE_BUSY)
    }

    const failure = (err) => {
      dispatch(types.LOGIN_SIGN_IN_ERR, err)
      commit(types.LOGIN_UPDATE_BUSY)
    }

    return noAuthHttp
      .get('auth/social/google')
      .then(success)
      .catch(failure)
  },

  [types.LOGIN_SOCIALITE_CB]: async ({
    commit,
    dispatch
  }, params) => {
    commit(types.LOGIN_UPDATE_BUSY)

    const success = ({ data }) => {
      dispatch(types.LOGIN_SIGN_IN_REDIR, { data, keepConnected: false })
      commit(types.LOGIN_UPDATE_BUSY)
      return data
    }

    const failure = (err) => {
      dispatch(types.LOGIN_SIGN_IN_ERR, err)
      commit(types.LOGIN_UPDATE_BUSY)
      return err
    }

    return http
      .get('auth/social/google/core-callback', { params })
      .then(success)
      .catch(failure)
  },

  [types.LOGIN_SEND_EMAIL]: async ({ commit }, email) => {
    const success = () =>
      commit(types.LOGIN_UPDATE_FEEDBACK, 'success')
    const failure = () =>
      commit(types.LOGIN_UPDATE_FEEDBACK, 'err')

    await http
      .post('forgot-password', { email })
      .then(success)
      .catch(failure)
  },

  [types.LOGIN_VALIDATE_RESET_TOKEN]: async ({
    commit
  }, { token, email } = {}) => {
    await http
      .post('user/password/token-verify', { token, email })
      .then(({ data }) => {
        if (!data.valid)
          return commit(types.LOGIN_UPDATE_FEEDBACK, 'expired')

        commit(types.LOGIN_UPDATE_RESET_TOKEN, token)
        commit(types.LOGIN_UPDATE_RESET_EMAIL, email)
      })
      .catch(async () => {
        commit(types.LOGIN_UPDATE_FEEDBACK, 'expired')
        const domain = process.env.VUE_APP_COOKIE_DOMAIN

        await http.post('logout').catch(err => console.log('Logout Error: ', err))

        document.cookie.split(';').forEach(appCookie =>
          Cookie.remove((appCookie.split('=')[0] || '')
            .trim(), { domain }))
      })
      .then(() => { commit(types.LOGIN_UPDATE_CONTENT, 'passwordReset') })
  },

  [types.LOGIN_RESET_PASSWORD]: async ({
    commit,
    getters
  }, data) => {
    const payload = {
      token: getters[types.LOGIN_RESET_TOKEN],
      email: getters[types.LOGIN_RESET_EMAIL],
      password: data.password,
      password_confirmation: data.password_confirmation
    }

    await http
      .post('reset-password', payload)
      .then(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'success') })
      .catch(() => { commit(types.LOGIN_UPDATE_FEEDBACK, 'err') })
  },

  // TODO: Move this to the base or settings module
  [types.LOGIN_GET_CAPTCHA_TOKEN]: ({ commit }) => {
    const update = token => commit(
      types.LOGIN_UPDATE_CAPTCHA_TOKEN,
      token)

    /* eslint-disable no-undef */
    const getCaptcha = () => grecaptcha.ready(() => grecaptcha
      .execute(process.env.VUE_APP_CAPTCHA_TOKEN, { action: 'login' })
      .then(update))

    if (window.hasOwnProperty('grecaptcha'))
      getCaptcha()
    else
      setTimeout(getCaptcha, 2000)
  },

  [types.LOGIN_LOGOUT]: async () => {
    const domain = process.env.VUE_APP_COOKIE_DOMAIN
    // const logoutV2Api = process.env.VUE_APP_LOGOUT_V2
    // const logoutV2 = () => fetch(logoutV2Api, { method: 'GET' })

    await Promise.all([
      // logoutV2(),
      http.post('logout')
    ]).catch(err => console.log('Logout Error: ', err))

    document.cookie.split(';').forEach(appCookie =>
      Cookie.remove((appCookie.split('=')[0] || '')
        .trim(), { domain }))
  }
}

export default { state, actions, mutations }
